

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: auto;
}

body {
    font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  height: auto;
  background-color: #F4F7FE;
}
.main{
  background-color: #F4F7FE;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

@font-face {
  font-family: bl-bold;
  src: url(../fonts/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff);
}

@font-face {
  font-family: bl-medium;
  src: url(../fonts/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff);
}

@font-face {
  font-family: bl-thin;
  src: url(../fonts/Geomanist-Thin-Webfont/geomanist-thin-webfont.woff);
}

@font-face {
  font-family: bl-regular;
  src: url(../fonts/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff);
}

@font-face {
  font-family: bl-light;
  src: url(../fonts/Geomanist-Light-Webfont/geomanist-light-webfont.woff);
}

@font-face {
  font-family: bl-extralight;
  src: url(../fonts/Geomanist-ExtraLight-Webfont/geomanist-extralight-webfont.woff);
}

@font-face {
  font-family: bl-black;
  src: url(../fonts/Geomanist-Black-Webfont/geomanist-black-webfont.woff);
}

.nav-link {
  font-family: bl-medium;
  color: #000;
  text-transform: capitalize;
}

.wrapper {
  display: flex;
}

#left {
  flex: 1;
  color: black;
  background-color: #fff;
}

#right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1;
  background-color: #fff;
  color: black;
}

/* sign in */
.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding-bottom: 1rem;
}

.sign-in .logo {
  margin-bottom: -20px;
  margin-top: 50px;
}

.sign-in form {
  width: 80%;
  padding-bottom: 3rem;
}

.sign-in label {
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 500;
  font-family: bl-regular;
}

.sign-in .text-input {
  margin-bottom: 1.3rem;
  width: 100%;
  padding: 0.5rem 1rem;
  line-height: 1.3rem;
  color: #ccc;
  font-family: bl-regular;
  border-radius: 12px;
  background-color: #f6f7f9;
  border: none;
  outline: none;
}

.sign-in .text-input:hover {
  margin-bottom: 1.3rem;
  width: 100%;
  padding: 0.5rem 1rem;
  line-height: 1.3rem;
  color: #ccc;
  font-family: bl-regular;
  border-radius: 12px;
  background-color: #f6f7f9;
  border: none;
}


.sign-in .links a {
  margin-bottom: 1rem;
  display: block;
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 0.9rem;
  font-family: bl-regular;
}


.bar {
  flex: auto;
  border: none;
  height: 1px;
  background: #aaa;
}

.sign-in span {
  color: #ccc;
  padding: 0 0.8rem;
  font-family: bl-regular;
}

/* Button */

.btn {
  display: block;
  background: #000;
  padding: 8px;
  height: 2.7rem;
  border: 0;
  border-radius: 2px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  width: 100%;
  font-family: bl-regular;
  /* margin-bottom: 1rem; */
}

.btn_sign {
  display: block;
  background: #1779f6;
  padding: 8px;
  height: 2.7rem;
  border: 0;
  border-radius: 2px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  width: 100%;
  font-family: bl-regular;
  border-radius: 12px;
  font-family: bl-regular;
}

input {
  border-radius: 12px;
}

.btn:hover {
  background-color: #167af3;
  font-family: bl-regular;
  color: #fff;
}

.secondary-btn {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
  border: 1px solid #167af3;
  padding: 0.7rem 1rem;
  font-family: bl-regular;
  border-radius: 12px;
}

.secondary-btn:hover {
  color: #167af3;
  border: 1px solid #167af3;
  font-family: bl-regular;
}

#main-footer {
  color: #ccc;
  text-align: center;
  font-size: 0.8rem;
  max-width: 80%;
  padding-top: 1rem;
}

#main-footer a {
  color: #f96816;
  text-decoration: underline;
  font-family: bl-regular;
}

.showcase {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.showcase .showcase-text {
  font-size: 3rem;
  width: 100%;
  margin-bottom: 1.5rem;
  font-family: bl-regular;
  margin-top: -110px;
}

.showcase .secondary-btn {
  width: 60%;
  margin: auto;
  font-family: bl-regular;
}

.home_sec {
  width: 100%;
  /* display: flex; */
  background-color: #111727;
  margin-top: 30px;
  border-radius: 12px;
  height: auto;
  color: white;
  padding: 20px;
  font-family: bl-regular;
}

.home_inner_first {
  display: flex;
  width: 100%;
  font-family: bl-regular;
}

.home_left {
  width: 70%;
}

.home_right {
  width: 30%;
  float: right;
}

.home_right button {
  width: 40%;
  float: right;
  border-radius: 12px;
}

.home_inner_second {
  width: 100%;
  margin-top: 20px;
  display: flex;
  background-color: #26303d;
  border-radius: 12px;
  padding: 20px;
}

.home_inner_left {
  width: 70%;
}

.home_inner_right {
  width: 30%;
}

.home_inner_right h5 {
  float: right;
  font-family: bl-regular;
}

.billing_history {
  width: 100%;
  display: flex;
  margin-top: 40px;
}

.invoice_table {
  margin-top: 40px;
}

.home_menu {
  display: flex;
  border: 1px solid #aaa;
  border-radius: 0px;
  padding-top: 0px;
  margin-top: 40px;
  height: 45px;
  font-family: bl-regular;
}

.home_menu p {
  font-size: bold;
  margin-left: 20px;
  font-family: bl-regular;
  padding: 10px;
  cursor: pointer;
  font-family: bl-regular;
}

.home_menu p:hover {
  border-radius: 12px;
  font-family: bl-bold;
}

#your_setting {
  margin-top: 40px;
  font-family: bl-bold;
}

.home_inner {
  display: flex;
  font-family: bl-regular;
}

.dropDownProfile {
  position: absolute;
  top: 5.5rem;
  right: 2rem;
  width: 140px;
  padding: 10px;
  border-radius: 12px;
  background-color: white;
  margin-right: 70px;
  height: 87px;
  margin-top: 105px;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
}



.dropDownProfile li {
  width: 100%;
  float: left;
  margin-top: 7px;
  font-family: "bl-regular";
  text-transform: capitalize;
  color: #1f1f1f;
  list-style: none;
  margin-left: -10px;
}

.dropDownProfile li:hover {
  color: #aaa;
}


#nav-profile {
  cursor: pointer;
  font-size: 35px;
  margin-top: 10px;
  color: #ed4d41;
}

img#profile-pic {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  float: left;
  margin-left: 0px;
  margin-right: -62px;


}

button#btn-flow {
  border: none;
  background-color: white;
  opacity: 1;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  float: right;
  margin-top: -11px;
}

.dropZoneOverlay,
.FileUpload {
  width: 283px;
  height: 71px;
}

.dropZoneOverlay {
  border: dotted 1px;
  font-family: cursive;
  color: #7066fb;
  position: absolute;
  top: 0px;
  text-align: center;
}

.FileUpload {
  opacity: 0;
  position: relative;
  z-index: 1;
}

.button_tabel:first-child {
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #aea7a7;
  color: black;
}

.button_tabel {
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #aea7a7;
  color: black;

}

.modal_btn button {
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #aea7a7;
  color: black;
  width: 100%;
}

.link {
  text-decoration: none;
}

.forget_password {
  float: right;
  font-family: bl-regular;
  margin-bottom: 10px;
}

.forget_password a {
  color: #1779f6 !important;
  margin-bottom: 10px;
  font-weight: 800;
}

a {
  text-decoration: none;
  color: black;
}



/* Media Query */
@media (min-width: 900px) {
  .left {
    flex: 2;
  }

  .right {
    flex: 6;
  }
}

@media (max-width: 768px) {
  body {
    overflow: auto;
  }

  #right {
    justify-content: start;
    margin-top: 4vh;
  }

  #left {
    display: none;
  }

  .sign-in .logo {
    margin-bottom: 2vh;
  }

  .sign-in .text-input {
    margin-bottom: 0.7rem;
    /* border-radius: 12px; */
  }

  #main-footer {
    padding-top: 1rem;
  }

  .table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    text-align: center;
  }
}


span.navbar-brand a {
  font-size: 35px;
  font-family: 'bl-bold';
  color: #6CCB00;
}

button.btn.btn-primary {
  border-radius: 0px;
  margin-top: 0px;
}

input#validationCustom03 {
  height: 35px;
  font-size: 12px;
  margin-top:0px;
}

.sign-in label {
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 500;
  font-family: 'bl-medium';
  margin-top: 20px;
}

.sign-in .logo h1 {
  margin-top: -100px;
  font-family: 'bl-medium';
}

form.form {
  margin-top: -80px;
}

#logo {
  width: 40px;
}

select.form-select {
  height: 40px;
  margin-top: 2px;
  border: solid black 0.5pt;
  border-radius: 10px;
}

a.nav-link {
  margin-left: 37px;
  margin-top: 11px;
  float: left;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  margin-top: -150px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

}

.container {
  width: 100%;
  margin-top: 150px !important;
  height: auto;
}

table td:nth-child(4) {
  color: #000;
  font-family: bl-medium;
}

.signupHomepage .eyeviewer {
  color: #333333;
  margin-top: -10px;
  margin-left: 90%;
  position: absolute;
}


.rg-form {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  margin-top: 10px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}



#pdf {
  width: 30px;
}


.uploaded a {
  width: 40%;
  float: left;
  margin-left: 0px;
}

.uploaded a:first-child {
  margin-left: 0px;
}

.customModal.modal-body label {
  margin-top: 5px;
  font-family: 'bl-medium';
  font-size: 18px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: grey;
  opacity: 0.4;
}

#google {
  background-color: white;
  border: none;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

#gicon {
  font-size: 20px;
  margin-right: 10px;
  margin-top: -3px;
}

.shippingDetails {
  width: 100%;
  float: left;
}

.shippingDetails p {
  margin-top: 10px;
}

.not {
  text-align: center;
  padding: 20px;
}

#logologin {
  width: 400px;

}

.sign-in label {
  float: left;
  color: black;
  margin-top: 0px;
}

.sign-in input {
  height: 45px;
  border-radius: 0px;
  margin-top: 0px;
  border: solid #000 0.1pt !important;
}

.sign-in button {
  height: 45px;
  border-radius: 0px !important;
  margin-top: 0px !important;
}

.secondary-btn,
.links {
  width: 80%;
  margin-top: 50px !important;
  font-family: bl-regular;
  border: solid black 1px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: white !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.container {
  width: 100%;
  margin-top: 150px !important;
  height: 35px;
}

.btn {
  display: block;
  background: #000;
  padding: 0px;
  height: 2rem;
  border: 0;
  border-radius: 2px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  width: 100%;
  font-family: bl-regular;
  font-size: 14px;
}

p {
  margin-top: 0;
  margin-bottom: 0.1rem;
}

.billing_history {
  width: 100%;
  display: flex;
  margin-top: -70px;
}

.mb-3 {
  margin-bottom: 1rem !important;
  width: 100%;
  float: left;
}

.customModal.modal-body label {
  width: 25% !important;
  font-size: 14px !important;
  ;
  float: left;
}

.customModal.modal-body input {
  width: 75%;
  font-size: 16px;
  float: left;
  border-radius: 0px;
  border: solid #000 0.1pt !important;
}

.navmenu a {
  margin-left: 20px;
}

.statisc {
  width: 400px;
}

.statisc label {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.statisc select {
  width: 50%;
  float: left;
  height: 45px;
  margin-top: 10px;
}

.setting_card {
  padding: 30px;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  margin-bottom: 100px;
  z-index: 7;
}

.username {
  width: 250px !important;
  float: left;
  margin-right: -50px;
}

#username {
  margin-left: 10px;
  float: left;
}
.signup{
  margin-top: 100px;
}

.editable_data{
  margin-left: 110px;
}
#logout{
  background-color: #FFE6E2;
  width: 90%;
  margin-left: 5%;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
}
.logout{
    color: red;
      margin: 16px;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      background-color: white;
      width: 85%;
      height: 50px;
      text-align: center;
      padding-top: 20px;
      border-radius: 10px;
      height: 150px;
      box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.01);
}
/* New Css for Control Pannel */

.main{
  width: 100%;
  /* height: auto; */
  display: flex;
  height: 100vh;
}

.left_sidebar {
  width: 18%;
  background-color: #F8F8FA;
  padding: 20px;
  box-shadow: 0px 4px 16px rgba(73, 80, 87, 0.1);
  position: fixed;
  z-index: 9;
  overflow: hidden;
  height: 100%;
  padding-left: 0px;
}

.left_sidebar_text{
  margin-top: 5vh;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

.left_sidebar_text p {
  font-weight: 800;
  font-size: 30px;
  margin-left: 14px;
  padding-bottom: 36px;
  border-bottom: solid #f1f1f1 1px;
  color: #1B254B;
  width: 167px;
}

.left_sidebar_text li{
  margin-top: 2vh;
  list-style-type: none;
}
::-webkit-scrollbar {
  width: 0.5em;
}

/* Hide the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Hide the scrollbar track on hover */
::-webkit-scrollbar-track:hover {
  background-color: transparent;
}
.right_sidebar{
  width: 93.8%;
  float: left;
  margin-left: 8.67%;
  margin-top: 4px;
  padding: 20px;
  padding-top: 40px;
  height: 104%;
  overflow-x: scroll;
  background-color: #F6F6F6;

}
.right_sidebar h3{
  font-size: 10px;
}
.topbar h3 {
  font-size: 14px;
  margin: 10px;
}

.border-bottom{
  border-bottom: none;
}
option{
  cursor: pointer;
}


.rs_in_first{
  display: flex;
  width: 86%;
  justify-content: flex-start;
  margin-top: 30px;
}

.rs_in_second{
  margin-top: 3%;
  width: 100%;
}

.rs_in_second h3{
  margin-top: 20px;
}


.rs_in_card{
  display: flex;
  justify-content: space-between;
  margin-top: 30px !important;
}

.rs_in_third{
  margin-top: 3%;
  padding: 20px;
  border: none;
}

.rs_in{
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  width: 38%;
  margin-left: 12px;
}

.rs_in h6{
  width: 10px;
  font-weight: 700;
  width: 100%;
  color: #1f1f1f;
  font-family: bl-regular;
}
.rs_in p {
  width: 10px;
  font-weight: 700;
  width: 100%;
  font-size: 20px;
  color:#1B2559;
}
option.cursor.px-3.py-2 {
  font-size: 17px;
  margin-top: 0px;
  font-weight: 500;
  color: #1f1f1f;
  font-family: bl-regular;
}

a.link.active {
  background: white;
  padding-left: 20px;
  padding-bottom: 7px;
  border-left: solid 2px #422AFB;
  border-radius:0px 10px 10px 0px;
  opacity: 1;
  height: 40px;
}

a.link {
  height: 40px;
    padding-left: 25px;
    padding-bottom: 7px;
    border-radius: 0px;
    font-family: bl-regular!important;
    opacity: 0.8;
}
.list-group a {
  margin-top: 5px!important;
}
.right_sidebar h3 {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
}
#growth{
  font-size: 12px;
}
.topbar{
  border-radius: 18px;
  padding: 10px;
  width: 80%;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 19%;
  z-index: 9a;
  position: fixed;
  box-shadow: none;
  -webkit-filter: none;
  filter: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-left: 40px;
  padding-right: 40px;
  height: 80px;
  overflow: hidden;
}
#mainHeading{
  margin-left:10px;
  width: 100%;
  margin-top: 0px;
  color: #1B254B;
  text-transform: capitalize;
  float: left;
  font-size: 22px;
  font-weight: 700;
}
.rs_in_third.card.border-0 {
  border-radius: 20px;
  background-color: transparent;
  margin-left: -20px;
  float: left;
  width: 100%;
}


.rs_in_third.card.border-0 table{
  width: 90%;
  border-radius: 10px;
}
.rs_in_third.card.border-0 form {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.leftMenu{
  width: 16%;
  float: left;
  background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.02);
    margin-right: 40px;
    margin-left: -35px;
    border-radius: 10px;

}


.leftMenu h3{
  font-family: bl-regular;
}

.leftMenu h1{
  font-family: bl-bold;
  font-size: 14px;
  margin-left: 10px;
}
.rightMenu {
  width: 25%;
  padding: 6px;
  float: right;
  border-radius: 100px;
  background-color: white;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.02);
  background-color: #fff;
}
.usernameTop{
  width: 40px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
  color: white;
  margin-right: 0px;
  float: right;
  padding: 7px;
  background-color: #1B254B;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color:#f0f0f0;
}

.loginForm{
  width:350px;
  background-color:#fff;
  border-radius: 10px;
  padding: 40px;
  margin-top: -100px;
}

button.btn.btn-primary{
  padding: 0px;
  border-radius: 4px;
}
input#validationCustom03 {
  border: solid #f1f1f1 2px;
}
.loginForm h1{
  font-size: 25px;
  text-align: left;
  font-weight: 800;
  margin-bottom: 20px;
}
.form-label {
  margin-bottom: 0.5rem;
  FONT-SIZE: 12px;
  font-size: 12px;
  margin-top: 10px;
  font-weight: 400;
}
#approve{
  background-color:#0068ff;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  width: 100px;
  margin-top: -10px;
  font-weight: 600;
  font-size: 14px;
}
#reject {
  background-color: red;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  width: 70px;
  margin-top: -4px;
  font-weight: 600;
  font-size: 14px;
  margin-left: -5px;
}
#edit {
  background-color:grey;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  width: 70px;
  margin-top: -4px;
  font-weight: 600;
  font-size: 14px;
  margin-left: -5px;
}
.fullname{
  width: 70%;
  padding: 14px;
  margin-left: 10px;
  overflow: hidden;
  float: left;
  padding-bottom: 0px;
}
.fullname h2{
  font-size:16px;
  font-weight: 700;
  color:#111727;

}
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999999;
}

#total {
  margin-bottom: 20px;
  font-family: 'bl-medium';
}
.rs_in_third input{
  width: 100%;
  border: 0;
  background-color: #f0f0f0;
  border-radius: 0px;
  height: 40px;
  margin-top: 20px;
}
.rs_in_third button {
  width:80px;
    border: 0;
    background-color:#000;
    border-radius: 0px;
    height: 20px;
    font-size: 12px;
    color: white;
    border-radius: 100px;
    cursor: pointer;
}
.rs_in_third label {
margin-top: 20px;
}
.rs_in_third select {
  width: 100%;
    border: 0;
    background-color: #f0f0f0;
    border-radius: 0px;
    height: 40px;
    margin-top: 20px;
}
.rs_in_third textarea {
  width: 100%;
  border: 0;
  background-color: #f0f0f0;
  border-radius: 0px;
  height: 40px;
  margin-top: 20px;
  height: 200px;
}

.rs_in_third form{
  width: 50%;
}

a.link {
  margin-top: -15px;
}

.pages button{
  width: 100px;
  margin: 010px;
}

.top{
  margin-top: 20px;
}

#removeItem{
  width: 100px;
}
#toc input{
  width: 20%;
  margin-left: 10px;
}
#toc select {
  width: 20%;
    margin-left: 10px;
}
.list-group svg {
  float: left;
  margin-top: 13px;
  margin-right: 9px;
  z-index: 99999;
  color: #1f1f1f;
}

.right-section{
  padding: 100px;
}

.graphs{
  float: left;
  margin-top: 0px;
}

.graphs #graph{
  width: 25%;
  float: left;

}

.graph{
  width: 100%;
  padding-right: 200px;
}

table.table td {
  font-family: 'bl-regular';
}
table.table th {
  font-family: 'bl-bold';
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  font-family: 'bl-regular';
}
a {
  color: var(--bs-link-color);
  text-decoration: underline;
  text-decoration: none;
  font-family: 'bl-medium';
}


table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 10px;
  border: 1px solid #fff;
}

tr:nth-child(odd) {
  background-color: white;
  border: solid #F8F8FA 1px;
}

tr:nth-child(even) {
  background-color: #F8F8FA;
}

.daily{
  background-color: #0056FF;
  width: 220px;
  border-radius: 20px;
}

.daily h6{
  color: white;
  font-family: bl-regular;
}

.daily p {
  font-size: 30px;
  color: white;
  font-family: bl-regular;
  margin-left: -20px;
  text-align: center;
  width: 51px;
  float: left;
  border-radius: 8px;
  margin-top: 20px;
}

#users{
  padding: 4px;
  border-radius: 5px;
    border: 1px dashed #FFF;
  width: 35px;
  height: 33px;
  margin-right: 10px;
  text-align: center;
  opacity: 0.9;
  float: left;
  margin-top: -5px;
}
#divUsers{
  padding: 4px;
    border-radius: 5px;
    border: 1px dashed #0068ff;
    width: 35px;
    height: 33px;
    margin-right: 10px;
    text-align: center;
    color: #0056FF;
    float: left;
    margin-top: -5px;
}

.leftMenutwo {
  width: 60%;
  margin-left: -20px;
    float: left;
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.02);
    margin-right: 40px;
    border-radius: 10px;
    padding: 18px;

}

.leftMenutwo h3{
  color:#fff;
  font-size: 18px;
  font-family: bl-regular;
}

.logout h1{
font-size: 18px;
font-family: bl-medium;
color: #18112D;
text-align: left;
margin-top: 5px;
margin-left: 20px;
}
.logout h2 {
  font-size: 16px;
  font-family: bl-regular;
  color: #18112D;
  opacity: 0.4;
  text-align: left;
  margin-left: 20px;
}
.logout img{
  width: 30px;
  float: left;
  margin-left: 20px;
  margin-right: 10px;
  height: 30px;
  border-radius: 10px;
}

#logo {
  width: 30px;
  font-size: 28px;
  font-family:'bl-bold';
  color: #18112D;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.lists{
  font-family: bl-medium;
  width: 25%;
  text-align: center;
  float: left;
  cursor: pointer;
  border-right:solid 1px #f0f0f0;

}

td img {
  border-radius: 100px;
}

.lists p{
  float: left;
  margin-left: 5px;
  height: 10px;
}
.rs_in_third.card.border-0 form textarea{
  padding: 20px;
}
.rs_in_third.card.border-0 form input {
  padding-left: 0px;
  height: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.rs_in_third.card.border-0 form button {
  padding: 10px;
  margin-top: 40px;
  height: auto;
  width: 200px;
}

.list-group{
  height: 500px;
  width: 100%;
  float: left;
  overflow-y: scroll;
}



#number{
  margin-top: 20px;
  font-size: 30px;
}

#trending{
  background-color: white;
  margin-left:0px ;
  padding: 18px;
  float: left;
  margin-top: 2px;
color: #0056FF;
  font-family: bl-medium;
  width: 250px;
  text-align: left;
  font-size: 18px;
  border-radius: 12px;
}
#trndIco{
  font-size: 22px;
  float: left;
  color: #0056FF;
  margin-left: 10px;
  margin-right: 20px;
}

#table td {
  width: 50%;
  float: left;
  font-family: 'bl-medium';
}

#table td input {
  width: 100%;
  float: left;
  border-radius: 0px;
  border: none;
  font-family: 'bl-regular';
}

#table tr {
  width: 100%;
  float: left;
}

#table select {
  word-wrap: normal;
  width: 100%;
  border: none;
  font-family: 'bl-regular';
}


#btn{
  width: 150px;
  border:none;
  background-color: #000;
  border-radius: 200!important;
  color:white;
  padding:4px;

}

#msg{
    font-family: 'bl-regular';
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  width: 200%!important;
  margin-left: -50%;
}
.parent {
  background: white;
  margin: 10px;
  padding: 20px;
}
.parent-node {
  font-weight: 900;
}
.child-node {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  background: #f9f9f9;
  padding: 10px;
  width: 42%;
}
#logp{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 20px;
}
.total {
  padding: 10px;
  margin: 20px;
  margin-left: -20px;
  background: linear-gradient(to right, #0068ff, white);
  width: 300px;
  color: #fff;
  font-weight: 700;
  text-align: left;
}
